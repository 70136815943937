.nav-item > .active {
    border-bottom: 1px solid #ffffff;
}

#imprint {
    padding-left: 20%;
    padding-right: 40%;
    min-height: 786px;
    background: #ffffff;
    padding-top: 12rem;
    overflow: hidden;
  }

  /* Recipe
  */
  @import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);
  @import url(https://fonts.googleapis.com/css?family=Pacifico);
  @import url(https://fonts.googleapis.com/icon?family=Material+Icons);
  @import "~slick-carousel/slick/slick.css";
  @import "~slick-carousel/slick/slick-theme.css";

  .slick-slider button {
    margin-right: 0;
  }
  .slick-prev:before, .slick-next:before {
    color: #223173 !important;
  }
  .recipe-wrapper img {
    width: 100%;
    padding: 1em;
  }
  .main-content {
    background: #fff;
  }
  .recipe-wrapper {
    padding-left: 2em;
    padding-right: 2em;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .main-content ul {
    padding: 1em 0 1em 2em;
    list-style-type: circle;
  }
  .main-content ol {
    padding: 0 0 0 2em;
  }
  
  .recipe-header {
    padding: 1em;
    text-align: center;
  }

  .recipe-wrapper i.material-icons {
    vertical-align: bottom;
    font-size: 1.3em;
    margin-right: 0.3em;
  }

  .recipe-wrapper p {
    margin: 0.5em 0;
  }
  .recipe-wrapper i.material-icons {
    vertical-align: bottom;
    font-size: 1.3em;
    margin-right: 0.3em;
  }
  .recipe-wrapper .back-link {
    font-size: 2em;
  }
  .recipe-wrapper hr {
    clear: both;
  }
  .recipe-header {
    padding: 1em;
    text-align: center;
  }
  .recipe-title {
    margin: 0;
  }
  .recipe-description {
    opacity: 0.6;
  }
  .recipe-ingredients-wrapper {
    background: #edeff0;
    padding: 1em;
    width: 42%;
    float: left;
    margin-right: 5%;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .recipe-directions {
    padding: 1em;
    margin-top: 2em;
    margin-left: 47%;
  }
  .recipe-directions li {
    margin-bottom: 0.5em;
  }
  .recipe-directions ol {
    list-style: none;
    counter-reset: counter;
    padding: 0;
  }
  .recipe-directions ol li {
    counter-increment: counter;
  }
  .recipe-directions ol li::before {
    content: counter(counter) ". ";
    color: #d9b45a;
    font-weight: bold;
    font-size: 2em;
  }
  .recipe-wrapper:after {
    content: "";
    display: table;
    clear: both;
  }
  .recipe-directions h3,
  .recipe-ingredients-wrapper h3 {
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }
  .recipe-ingredients {
    list-style-type: none !important;
    padding: 0 !important;
  }
  .recipe-ingredients li {
    padding: 0.5em 0;
    border-bottom: 1px #d9b45a dashed;
  }
  .recipe-ingredients li:last-child {
    border-bottom: none;
  }
  
  .recipe-content img,
  .recipe-img, 
  .cover-img {
    width: 100%;
    border-radius: .3em;
    height: 250px;
    object-fit: cover;
  }
  .cover-img {
    margin-bottom: 1.25em;
  }
  .gallery.fullscreen .recipe-img {
    height: auto;
  }
  .gallery small {
    text-align: center;
    display: block;
    cursor: zoom-in;
  }
  .gallery.fullscreen small {
    cursor: zoom-out;
  }
  .not-found {
    text-align: center;
  }


  /* ================================= 
  MEDIA QUERIES
==================================== */

@media (min-width: 0) and (max-width: 768px) { 
  .main-content {
    padding: 0.5em;
  }
  .recipe-ingredients-wrapper {
    margin: 2em 0 1em;
    width: 100%;
    float: none;
  }
  .recipe-directions {
    margin-top: 1em;
    padding: 1em 0 0 0;
    padding-bottom: 0;
    margin-left: 0;
  }
}
@media (min-width: 769px) {
  .media img,
  .media > div,
  .media > div {
    width: 85.1%;
    margin-left: 1.5em;
  } 
}